import { getLoggedInUserId } from './authAction'
import { postApiWrapper } from './token-wrapper-function';

export const joinNodes = async (data) => {
  const loggedInNodeId = getLoggedInUserId();
  const url = `/api/tasks/run_actions.php`;
  const postBody = {
    logged_in_node_id: loggedInNodeId,
    ...data,
  };
  const result = await postApiWrapper(url, postBody);
  return result;
};

export const addNodes = async (data) => {

  const loggedInNodeId = getLoggedInUserId();
  const url = `/api/tasks/run_actions.php`;
  const postBody = {
    logged_in_node_id: loggedInNodeId,
    ...data,
  };
  const result = await postApiWrapper(url, postBody);
  return result;
};

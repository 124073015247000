import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { addNodes, joinNodes } from '../../actions/addRelationsAction';
import { searchUser } from '../../actions/searchAction';
import { koot_falan } from '../../helpers/utils';
import LoadingSpinner from '../loader';
import SelectGautra from './select-gautra';
import SelectName from './select-name';
import SelectVillage from './select-village';
import ToastMessage from './toast-message';
import { InputGroup } from '@paljs/ui/Input';

import { partialDetailsApi } from '../../actions/familyTreeAction';
import { getLoggedInUser } from '../../actions/authAction';
import Modal from '../../Utilities/Modal';
import { Card, CardBody } from '@paljs/ui/Card';
import _ from 'lodash';
import SearchResultTable from '../search/search_result_table';

export default function AddRelation({
  selectedNode = [],
  relation,
  gender,
  getFamilyDetails,
  selectedPath = [],
  nodeDataToBeAdded = {},
  onClose,
}) {
  const [name, setName] = useState(nodeDataToBeAdded?.name ?? '');
  const [gautraObj, setGautraObj] = useState(
    selectedNode.gautra && relation != 'spouse' ? { gautra: selectedNode.gautra, subcaste: selectedNode.subcaste } : '',
  );
  const [subcaste, setSubcaste] = useState(
    `${selectedNode.subcaste && relation != 'spouse' ? selectedNode.subcaste : ''}`,
  );
  const [village, setVillage] = useState(`${selectedNode.village && relation != 'spouse' ? selectedNode.village : ''}`);
  const [isAlive, setAlive] = useState(true);
  const [age, setAge] = useState(nodeDataToBeAdded?.age ?? '');
  const [mobileNo, setMobileNo] = useState(nodeDataToBeAdded?.mobile ?? '');
  const [fatherName, setFatherName] = useState('');

  const [searchResult, setSearchResult] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState('');
  const [errorMsgGautra, setErrorMsgGautra] = useState('');
  const [errorMsgVillage, setErrorMsgVillage] = useState('');
  const [loggedInUser, setLoggedInUser] = useState({});
  const [searchParams, setSearchParams] = useState({});

  // const [errorMsgAge, setErrorMsgAge] = useState('');
  // const [errorMsgMobile,setErrorMsgMobile] = useState('');

  const newNodeRelation =
    relation == 'kid' ? (gender == 'female' ? 'Daughter' : 'Son') : relation == 'parent' ? 'Father' : relation;
  const addNodeMessage = `${name} as ${newNodeRelation} of ${selectedNode.name}`;

  const handleAddNode = async () => {
    //selected_node -> node from left of tree
    //existing_node -> node from search results

    const d = new Date();
    let year = d.getFullYear();

    let newNodeData = {
      // "logged_in_node_id": "{{initial_node_id}}",
      action: 'add_new_node',
      selected_node_id: selectedNode.uid,
      relationship: relation, // kid,parent or spouse
      new_node_name: name.trim(),
      koot_falan: name ? koot_falan(name) : '',
      new_node_gautra: gautraObj?.gautra,
      new_node_subcaste: gautraObj?.subcaste ?? '',
      new_node_gender: gender.trim(),
      new_node_village: village.trim(),
      new_node_mobile: mobileNo.trim(),
      alive: isAlive ? 'alive' : 'dead',
      born: age ? year - age : '',
    };

    // const isSpouse = selectedNode.gender == 'female' && selectedNode.spouse.length != 0 ? true : false;
    const isSpouse = selectedPath.length == 0 ? true : false;

    try {
      setShowLoader(true);
      const response = await addNodes(newNodeData);
      setShowLoader(false);
      getFamilyDetails(selectedNode.uid, selectedPath, isSpouse);
      if (response.status) {
        toast.success(`Added ${addNodeMessage}`);
      } else toast.error(`Could not add ${addNodeMessage}\n Error: ${response.error_message}`);
    } catch (error) {
      console.log(`Could not add ${addNodeMessage}\n`, error);
      setShowLoader(false);
      toast.error(`Could not add ${addNodeMessage}\n Error: ${error}`);
    }
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = async () => {
    if (!name) setErrorMsgName('Please Enter Name');
    // else if (!gautraObj) setErrorMsgGautra('Please Select Gautra');
    // else if (!village) setErrorMsgVillage('Please Enter Village');
    // else if(!age) setErrorMsgAge('Please Enter Age');
    // else if(!mobileNo) setErrorMsgMobile('Please Enter Mobile No:');
    else if (!gautraObj || !village) {
      const updatedMetadata = {
        ...selectedNode?.metadata,
        spouse: {
          name: name,
          age: age,
          father: fatherName,
          gautra: gautraObj?.gautra ?? '',
          village: village,
        },
      };
      const data = {
        logged_in_node: loggedInUser.graph_node_id,
        metadata: updatedMetadata,
      };
      console.log('updated node details', data);
      setShowLoader(true);
      const response = await partialDetailsApi(selectedNode?.uid, data);
      if (response) {
        toast.success('Meta data added successfully');
      } else {
        toast.error(`got error while updating meta data ${response}`);
      }
      setShowLoader(false);
      if (onClose) {
        onClose();
      }
    } else {
      let sentValue = {
        name: name.trim(),
        gautra: gautraObj?.gautra,
        gender: gender.trim(),
        koot_falan: name ? koot_falan(name).trim() : '',
        subcaste: gautraObj?.subcaste ?? '',
        village_name: village.trim(),
        new_node_mobile: mobileNo.trim(),
      };

      if (name) {
        searchParams.name = name;
      }

      if (newNodeRelation == 'Son') {
        searchParams.father_name = selectedNode.name;
      } else if (newNodeRelation == 'spouse') {
        searchParams.father_name = fatherName;
      }

      if (gautraObj.gautra) {
        searchParams.gautra = gautraObj?.gautra;
      }
      if (gautraObj.subcaste) {
        searchParams.subcaste = gautraObj?.subcaste;
      }

      if (village) {
        searchParams.village = village;
      }

      setShowLoader(true);
      try {
        const response = await searchUser(sentValue);
        console.log('checking response in add relation', response);
        if (response.mobile_search_results?.length) {
          toast.error(`Mobile No. already present`);
        }
        const apiSearchResults = response?.search_results;
        if (apiSearchResults.length && !response.mobile_search_results?.length) {
          setSearchResult(apiSearchResults);
          setShowLoader(false);
        } else if (!response.mobile_search_results?.length) {
          // toast.error(`User with name ${name} not found`);
          handleAddNode();
        }
      } catch (error) {
        console.log(`Could not search user with name ${name}`, error);
        setShowLoader(false);
        toast.error(`Could not search user with name ${name}\n Error:${error}`);
      }
      setShowLoader(false);
    }
  };

  useEffect(() => {
    setLoggedInUser(getLoggedInUser());
  }, []);

  useEffect(() => {
    if (name) setErrorMsgName('');
    console.log('name:', name);
  }, [name]);

  useEffect(() => {
    if (gautraObj) setErrorMsgGautra('');
    console.log('gautra: ', gautraObj);
  }, [gautraObj]);

  useEffect(() => {
    if (village) setErrorMsgVillage('');
    console.log('village:', village);
  }, [village]);

  // useEffect(() => {
  //   if (age) setErrorMsgAge('');
  //   console.log('age:', age);
  // }, [age]);

  // useEffect(() => {
  //   if (mobileNo) setErrorMsgMobile('');
  //   console.log('Mobile No:', mobileNo);
  // }, [mobileNo]);

  const handleSelectedUser = async (selectedNodeFromList) => {
    setShowLoader(true);

    let finalNodeData = {
      action: 'join_existing_nodes',
      id_of_person_to_connect: selectedNodeFromList?.uid,
      selected_node_id: selectedNode?.id,
      gender_of_person_to_connect: selectedNodeFromList?.gender,
      relationship: relation,
    };

    const isSpouse = selectedPath.length == 0 ? true : false;
    try {
      const response = await joinNodes(finalNodeData);
      console.log('result from joining nodes ', response);
      getFamilyDetails(selectedNode?.uid, selectedPath, isSpouse);
      setShowLoader(false);
      if (response.status) toast.success(<ToastMessage message={`Added ${addNodeMessage}`} />, { autoClose: false });
      else toast.error(`Could not add ${addNodeMessage}\n Error: ${response.error_message}`);
    } catch (error) {
      console.log(`Could not add ${addNodeMessage}`, error);
      setShowLoader(false);
      toast.error(`Could not add ${addNodeMessage}\n Error: ${error}`);
    }
  };

  return (
    <Modal toggle={onClose}>
      <Card>
        <CardBody>
          {showLoader ? (
            <LoadingSpinner message={'Loading'} />
          ) : searchResult.length ? (
            <SearchResultTable
              searchResults={searchResult}
              setSelectedUser={(selectedNodeFromList) => handleSelectedUser(selectedNodeFromList)}
              noneOfThese={true}
              selectButton={true}
              onNoneOfTheseClick={handleAddNode}
              searchParams={searchParams}
              closeOnClick={false}
            />
          ) : (
            // <SearchResults
            //   selectedNode={selectedNode}
            //   name={name}
            //   searchResult={searchResult}
            //   relation={relation}
            //   getFamilyDetails={getFamilyDetails}
            //   selectedPath={selectedPath}
            //   addNode={handleAddNode}
            //   toastMessage={addNodeMessage}
            // />
            <div>
              <h1>
                Adding {newNodeRelation} for {selectedNode.name}
              </h1>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <label>
                  Name:
                  <br />
                  {isAlive ? '' : 'स्वर्गीय '}
                  <SelectName setName={setName} gender={gender} name={name} autoFocus={true} />
                  {errorMsgName ? <div style={{ color: 'red', fontWeight: '400' }}>{errorMsgName}</div> : ''}
                </label>

                <br />
                {newNodeRelation == 'spouse' ? (
                  <label>
                    Father's Name (optional):
                    <br />
                    <SelectName setName={setFatherName} gender="male" name={fatherName} />
                  </label>
                ) : (
                  ''
                )}
                <br />
                <label>
                  {relation == 'spouse' ? "Father's " : ''} Gautra:
                  <SelectGautra
                    setGautraObj={setGautraObj}
                    gautraObj={gautraObj}
                    disabled={relation == 'parent' || relation == 'kid'}
                  />
                  {errorMsgGautra ? <div style={{ color: 'red', fontWeight: '400' }}>{errorMsgGautra}</div> : ''}
                </label>
                <br />
                <label>
                  {relation == 'spouse' ? "Father's " : ''} Village:
                  {relation == 'kid' && gender == 'female' ? ' कृपया पिता के गांव का नाम लिखें ' : ''}
                  <SelectVillage setVillage={setVillage} village={village} />
                  {errorMsgVillage ? <div style={{ color: 'red', fontWeight: '400' }}>{errorMsgVillage}</div> : ''}
                </label>
                <br />
                <label>
                  Age:
                  <input
                    type="number"
                    style={{ fontSize: '15px' }}
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                  />
                  {/* {errorMsgAge ? <div style={{ color: 'red', fontWeight: '400' }}>{errorMsgAge}</div> : ''} */}
                </label>
                <br />
                <label>
                  Mobile No:
                  <input
                    type="text"
                    style={{ fontSize: '15px' }}
                    value={mobileNo}
                    onChange={(e) => setMobileNo(e.target.value.trim())}
                  />
                  {/* {errorMsgMobile ? <div style={{ color: 'red', fontWeight: '400' }}>{errorMsgMobile}</div> : ''} */}
                </label>
                <br />
                <label>
                  <input type="checkbox" onChange={() => setAlive(!isAlive)} checked={isAlive} />
                  Alive
                </label>
                <br />
                <input type="submit" />
              </form>
            </div>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
}

//village - always editable
//add spouse - gautra editable
//add parent - gautra not editable
//add kids - gautra not editable

import React, { useEffect, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { getSamajMitra } from '../../actions/samajMitraAction'; // Replace with actual API call.
import { searchResult } from '../../helpers/utils';
import { toast } from 'react-toastify';

const SelectSamajMitra = ({ setSamajMitra, setSamajMitraId, samajMitra = '', autoFocus = false }) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [firstTenOptions, setFirstTenOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getSamajMitra(); // Fetch volunteer data from your backend.
        console.log('response from get SamajmitraApi', res);
        const newData = res?.data?.map((samajMitra) => ({
          value: samajMitra?.name.trim(),
          label: samajMitra?.name.trim(),
          graphNodeId: samajMitra?.graph_node_id,
        }));
        console.log('newData', newData);
        setOptions(newData);
        setFirstTenOptions(newData.slice(0, 10)); // Show the first 10 options initially.
        setLoading(false);
      } catch (e) {
        console.log('Could not fetch volunteers', e);
        setLoading(false);
        toast.error(`Could not fetch volunteers\n, Error:${e}`);
      }
    };

    fetchData();
  }, []);

  const searchOptions = (inputValue) => {
    return searchResult(options, inputValue.trim());
  };

  let win_variable = '';
  if (typeof document !== 'undefined') {
    win_variable = document.body;
  }

  return (
    <AsyncCreatableSelect
      onChange={(option) => {
        // Handle case when the selection is cleared
        if (option) {
          if (setSamajMitraId) {
            setSamajMitraId(option?.graphNodeId); // Pass the graphNodeId of selected volunteer
          }
          setSamajMitra(option?.value.trim());
        } else {
          // Handle clearing the selection
          setSamajMitra('');
          if (setSamajMitraId) {
            setSamajMitraId(null); // Reset graphNodeId when cleared
          }
        }
      }}
      defaultValue={samajMitra ? { label: samajMitra, value: samajMitra } : null}
      value={samajMitra ? { label: samajMitra, value: samajMitra } : null}
      isLoading={isLoading}
      autoFocus={autoFocus}
      cacheOptions
      defaultOptions={firstTenOptions}
      loadOptions={searchOptions}
      placeholder="Select Samaj Mitra"
      isClearable
      maxMenuHeight={150}
      menuPlacement="bottom"
      menuPortalTarget={win_variable ?? <></>}
      styles={{
        option: (style) => ({ ...style, color: 'black' }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
    />
  );
};

export default SelectSamajMitra;

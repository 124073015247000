import React, { useState, useEffect } from 'react';
// import Modal from '../../components/Modal-wrapper/modal';
import AddRelation from './add-relation';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import EditBasicDetails from './edit-basic-details';
import Modal from "../../Utilities/Modal"
import styled from 'styled-components';

const AddSuggestedRelations = ({ onClose, nodeToBeUpdated, nodeToBeAdded, relationObj, suggestedChange, getFamilyDetails }) => {


    return (
       <>
            {
                suggestedChange == "ADD_NODE" ?
                    (
                                <AddRelation
                                    selectedNode={nodeToBeUpdated}
                                    relation={relationObj.relation}
                                    gender={relationObj.gender}
                                    getFamilyDetails={getFamilyDetails}
                                    selectedPath={[nodeToBeUpdated.uid]}
                                    nodeDataToBeAdded={nodeToBeAdded}
                                    onClose={onClose}
                                />
                    )
                    : suggestedChange == "UPDATE_DETAILS" ?
                        (
                            <StyledWrapper>
                                <EditBasicDetails
                                    editName={nodeToBeUpdated.name}
                                    node_id={nodeToBeUpdated.uid}
                                    onClose={onClose}
                                    updatePage={() => getFamilyDetails(nodeToBeUpdated.uid, [nodeToBeUpdated.uid])}
                                    nodeDetailsToUpdate={nodeToBeAdded}
                                />

                            </StyledWrapper>
                        )
                        :
                        ''
            }
        </>
    )
}

export default AddSuggestedRelations;

const StyledWrapper = styled.div`
  width: 100%;
`;